import { isAfter, isBefore } from "date-fns";
import { i18n } from "@/plugins/i18n";

export function required(message) {
  const msg = message ?? i18n.global.t("rules.requiredField");
  return (v) => !!v || msg;
}

export function email(message) {
  const msg = message ?? i18n.global.t("rules.emailIsValid");
  return (v) => /.+@.+/.test(v) || msg;
}

export function linkClicked(
  link,
  msg = "Please open documents before continuing!"
) {
  return () => !!link || msg;
}

export function betweenNumeric(min, max) {
  const re = new RegExp(`^(\\d{${min},${max}})$`);
  return (v) =>
    (v && re.test(v)) ||
    i18n.global.t("rules.betweenNumeric", {
      min,
      max,
    });
}

export function numberOnly(message) {
  const msg = message ?? i18n.global.t("DebitCardForm.invalidCVCFormat");
  const re = new RegExp(`^[0-9]*$`);
  return (v) => re.test(v) || msg;
}

export function orNumericIgnoringSpaces(min, max) {
  const re = new RegExp(`^(?:\\d{${min}}|\\d{${max}})$`);
  return (v) =>
    (v && re.test(v.replace(/\s/g, ""))) ||
    i18n.global.t("rules.orNumeric", {
      min,
      max,
    });
}

export function minLength(minLength, message) {
  return (v) => (v && v.length >= minLength) || message;
}

export function lenExactMatch(len, message) {
  return (v) => (v && v.length === len) || message;
}

export function firstCardMatch(firstcardVal, secondcardVal) {
  if (secondcardVal.length > 1) {
    return (
      firstcardVal === secondcardVal ||
      i18n.global.t("DebitCardForm.confirmCardNumbers")
    );
  }
}
export function isDateBefore(compareDate) {
  return (v) =>
    isBefore(new Date(v), new Date(compareDate)) ||
    i18n.global.t("rules.isDateBefore");
}

export function isDateAfter(compareDate) {
  return (v) =>
    isAfter(new Date(v), new Date(compareDate)) ||
    i18n.global.t("rules.isDateAfter");
}

export function inputPattern(pattern, message) {
  return (v) => pattern.test(v) || message;
}

export function nonZero(message) {
  return (v) => (v && v !== "000-000-0000") || message;
}

export function requiredAndNull(message) {
  const msg = message ?? i18n.global.t("rules.requiredField");
  return (v) => {
    if (v === null) {
      return true;
    }
    return !!v || msg;
  };
}

export function VisaPlusPayNameChk() {
  const paynameRegex = new RegExp("^[a-zA-Z 0-9'-_,]+(?:.[a-zA-Z]+)*$");
  return (v) =>
    (v && paynameRegex.test(v)) || i18n.global.t("visaPlusForm.invalidPayname");
}
export function masterCardType() {
  const regex = new RegExp(/^(5[0-5]|2(2(2[1-9]|[3-9])|[3-6]|7(0|1|20)))/);
  const mastroRegex = new RegExp(/^(5[0678])/);
  return (v) =>
    (v && regex.test(v)) ||
    mastroRegex.test(v) ||
    i18n.global.t("rules.masterIsValid");
}

export function visaCardType() {
  let re = new RegExp("^4");
  return (v) => (v && re.test(v)) || i18n.global.t("rules.visaIsValid");
}

export function detect_CardType() {
  const regex = new RegExp(/^(5[0-5]|2(2(2[1-9]|[3-9])|[3-6]|7(0|1|20)))/);
  let re = new RegExp("^4");
  const mastroRegex = new RegExp(/^(5[0678])/);
  return (v) =>
    (v && regex.test(v)) ||
    re.test(v) ||
    mastroRegex.test(v) ||
    i18n.global.t("rules.cardIsValid");
}
