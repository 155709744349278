<template>
  <v-app-bar
    id="appBar"
    class="px-sm-12"
    app
    color="white"
    :height="smAndUp ? 100 : 70"
    elevation="0"
  >
    <nav
      id="nav"
      class="d-flex w-full align-center justify-center"
      :aria-label="$t('TheNavBar.menu')"
    >
      <flagstar-logo
        v-if="getBankCode === '002' && authStore.retriveMerchantData"
        data-test="flagstar-logo"
        class="h-10"
      />
      <v-divider v-if="getBankCode === '002'" vertical></v-divider>
      <div>
        <v-spacer />

        <carat-logo
          data-test="carat-logo"
          class="h-8"
          :class="getBankCode === '002' ? 'caratlogo-pad' : ''"
        />
      </div>

      <v-tabs
        v-if="authStore.isUser && smAndUp"
        class="ml-10 flex-grow-1"
        color="primary"
        optional
      >
        <v-tab id="tab1" to="/" :ripple="false" tabindex="0">
          <svg-icon type="mdi" :path="mdiHomeOutline" size="24"></svg-icon>
          <div class="ml-2 primary--text">
            {{ $t("TheNavBar.dashboard") }}
          </div>
        </v-tab>
        <v-tab id="tab2" to="/payouts" :ripple="false" tabindex="0">
          <svg-icon
            class="ml-3"
            type="mdi"
            :path="mdiCashMultiple"
            size="24"
          ></svg-icon>
          <div class="ml-2 primary--text">
            {{ $t("TheNavBar.payouts") }}
          </div>
        </v-tab>
        <v-tab id="tab3" to="/faq" :ripple="false" tabindex="0">
          <svg-icon
            class="ml-3"
            type="mdi"
            :path="mdiFrequentlyAskedQuestions"
            size="24"
          ></svg-icon>
          <div class="ml-2 primary--text">
            {{ $t("TheNavBar.faq") }}
          </div>
        </v-tab>
      </v-tabs>

      <v-tabs
        v-else-if="!authStore.isUser"
        class="flex-grow-1"
        color="primary"
        :class="smAndUp && getBankCode !== '002' ? 'ml-10' : 'nycbMid'"
        optional
      >
        <v-tab id="guesttab1" to="/faq" :ripple="false" @click="showslider">
          <svg-icon type="mdi" color="primary" :path="iconDecider"> </svg-icon>
          <div class="primary--text ml-2">
            {{
              !authStore.isGuest && !authStore.isUser
                ? $t("TheNavBar.help")
                : $t("TheNavBar.faq")
            }}
          </div>
        </v-tab>
      </v-tabs>

      <the-user-menu v-if="authStore.isUser && smAndUp">
        <template #default="{ props }">
          <the-user-menu-button v-bind="props" />
        </template>
      </the-user-menu>
      <!-- <v-spacer /> -->
      <v-btn
        v-if="authStore.isGuest"
        tile
        variant="outlined"
        data-test="exit-btn"
        class="exitNavBtn"
        @click="exit"
        >{{ $t("TheNavBar.exit") }}</v-btn
      >
    </nav>
  </v-app-bar>
</template>

<script>
import CaratLogo from "@/assets/img/carat_logo.svg?inline";
import FlagstarLogo from "@/assets/img/flagstarbank_logo.svg?inline";
import TheUserMenu from "./TheUserMenu.vue";
import TheUserMenuButton from "@/components/TheUserMenuButton";
import {
  mdiCashMultiple,
  mdiHomeOutline,
  mdiFrequentlyAskedQuestions,
  mdiHelp,
} from "@mdi/js";
import { useAuthStore } from "@/stores/auth";
import { useDisplay } from "vuetify";

export default {
  name: "TheNavBar",

  components: {
    CaratLogo,
    FlagstarLogo,
    TheUserMenu,
    TheUserMenuButton,
  },

  setup() {
    const authStore = useAuthStore();
    const { smAndUp } = useDisplay();

    return { authStore, smAndUp };
  },
  data() {
    return {
      mdiHomeOutline,
      mdiCashMultiple,
      mdiFrequentlyAskedQuestions,
      mdiHelp,
    };
  },
  computed: {
    iconDecider() {
      return !this.authStore.isGuest && !this.authStore.isUser
        ? mdiHelp
        : mdiFrequentlyAskedQuestions;
    },
    getBankCode() {
      return this.authStore.retriveBankCode;
    },
    currentRouteName() {
      return this.$route?.name;
    },
  },

  mounted() {
    if (this.authStore.isGuest && !this.authStore.isFAQClicked) {
      const faqNode = document.querySelector(".v-tab__slider");
      faqNode.style.opacity = "0";
    }
  },
  methods: {
    exit() {
      this.authStore.signOut();
      this.$router.push({
        name: "session-ended",
        query: { guest: "true", source: "exit" },
      });
    },
    showslider() {
      const faqNode = document.querySelector(".v-tab__slider");
      faqNode.style.opacity = "1";
      this.authStore.setFaqSelected(true);
    },
  },
};
</script>

<style scoped>
.v-tabs--density-default {
  --v-tabs-height: 48px !important;
}
.theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.3);
}
.v-tab::before {
  background-color: white;
}

.v-tab:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
  outline-offset: -0.25rem;
}
.narrow-letter-spacing {
  letter-spacing: -0.125rem !important;
}
.v-tabs-slider-wrapper {
  bottom: auto !important;
}

.h-8 {
  height: 2rem;
}
.h-6 {
  height: 3rem;
}

.h-10 {
  height: 4rem;
}
.flagstar-logo {
  padding: 0 10px;
}

.nycbMid {
  width: 35%;
  margin-left: 0px !important;
}
.caratlogo-pad {
  padding: 0 20px;
}

/* Hack for reesponsive width 600 to 680 */
@media only screen and (min-width: 600px) and (max-width: 680px) {
  .ml-10 {
    margin-left: -10px !important;
  }
  .h-8 {
    margin-left: -18px;
    height: 1.5rem;
  }
}
</style>
